window.ClientHelper = {
    url: null,
    infoWindows: [],
    zones: [],
    markers: [],
    initializeZones: function(allZones){
        let self = this;
        self.zones = allZones;
        $('#search_city').change(function() {
            let selectedCityId= $(this).val();
            self.toggleZones(selectedCityId);
        });
    },
    toggleZones: function(cityId){
        let self = this;
        let selectedZones = self.zones.filter(item => item.city_id === Number(cityId));
        const $select = $('#search_zone');
        $select.empty();
        $select.append('<option value="">Seleccione una opción</option>');
        selectedZones.forEach(item => {
            $select.append(`<option value="${item.id}">${item.name}</option>`);
        });
    },
    loadZones: function(zoneId, map){
        let self = this;

        $("#marker-search").on("change", function () {
            const markerId = $(this).val();
            const marker = self.markers[markerId];

            if (marker) {
                map.setCenter(marker.getPosition());
                map.setZoom(21);

                $.get(`/tracking/clients/${markerId}/clients/${markerId}/popup`, function (data) {
                    self.createInfoWindow(map, marker, data);
                });
            }
        });

        $.post(self.url,
            {zone_id: zoneId},
            function(data) {
                self.clearMarkers();
                data.clients.forEach(function(coord) {
                    const customIcon = {
                        url: "/pines/" + data.zone_color + ".svg",
                        scaledSize: new google.maps.Size(40, 40),
                    };

                    const marker = new google.maps.Marker({
                        position: { lat: parseFloat(coord.lat), lng: parseFloat(coord.long) },
                        map: map,
                        title: 'Marker at ' + coord.lat + ', ' + coord.lng,
                        icon: customIcon
                    });

                    $("#marker-search").append(
                        `<option value="${coord.id}">${coord.name}</option>`
                    );

                    marker.addListener("click", () => {
                        $.get(coord.popup_url, function (data) {
                            self.createInfoWindow(map, marker, data);
                        });
                    });

                    self.markers[coord.id] = marker;
                });

                const bounds = new google.maps.LatLngBounds();
                self.markers.forEach(function(marker) {
                    bounds.extend(marker.getPosition());
                });
                map.fitBounds(bounds);
            }
        );
    },
    clearMarkers: function () {
        $("#marker-search").empty();
        $("#marker-search").append(
            `<option value="" disabled selected>Buscar un lugar...</option>`
        );
        let self = this;
        self.markers.forEach(function(marker) {
            marker.setMap(null);
        });
        self.markers = [];
    },
    createInfoWindow: function(map, marker, content) {
        let self = this;
        const infoWindow = new google.maps.InfoWindow({
            content: content,
        });
        self.closeAllInfoWindows();
        infoWindow.open(map, marker);

        self.infoWindows.push(infoWindow);
    },
    closeAllInfoWindows: function() {
        let self = this;
        self.infoWindows.forEach((infoWindow) => infoWindow.close());
    }
}